.quickmain{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 96%;
    margin-left: 2%;
    height: 100%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 1px 10px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 100px;
}
.quickmain .quickhead{
    margin: 20px 0;
    font-size: 2rem;
    font-weight: 800;
}
.quickmain .quicktextboxes{
    margin-bottom: 20px;
    width: 100%;
    display: flex;
}
.quickmain .quicktextboxes .textbox{
    width: calc(100%/3);
    display: flex;
    justify-content: center;
    align-items: center;
}
.quickmain .quicktextboxes .textbox .inner{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
}
.quickmain .quicktextboxes .textbox .inner input{
    height: 40px;
    width: 94%;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    outline: none;
    font-size: 1.3rem;
    margin-left: 2%;
}
.quickmain .quicksendbutton{
    width: 200px;
    border-radius: 25px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    height: 50px;
    background-color: #39151b;
    margin: 30px 0;
    margin-bottom: 60px;
    transition: all 0.3s ease;
    border: none;
    outline: none;
}
.quickmain .quicksendbutton:hover{
    width: 210px;
}
.quickmain .quicktextare{
    width: 95%;
}
.quickmain .quicktextare .textarea{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
}
.quickmain .quicktextare .textarea textarea{
    background-color: transparent;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    outline: none;
    font-size: 1.3rem;
    margin-left: 1%;
}
@media (max-width: 600px) {
    .quickmain{
        width: 92%;
        margin-left: 4%;
    }
    .quickmain .quicktextboxes{
        flex-direction: column;
    }
    .quickmain .quicktextboxes .textbox{
        width: 100%;
        margin: 10px 0;
    }
    .quickmain .quicktextare{
        width: 87%;
    }
}