.cirtificate{
    height: 100%;
    width: 100%;
    margin: 100px 0;
}
.cirtificate .cirtificatesec{
    display: flex;
    align-items: center;
    justify-content: center;    
}
.cirtificate .cirtificatesec img{
    height: 100%;
    width: 30%;
    margin: 0 50px;
}
.cirtificate span{
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}
@media (max-width: 600px) {
    .cirtificate{
        height: 100%;
        width: 100%;
        margin: 50px 0;
    }
    .cirtificate .cirtificatesec{
        flex-direction: column;  
    }
    .cirtificate .cirtificatesec img{
        width: 90%;
        margin: 30px 0;
    }
}