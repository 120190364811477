
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}
.homenew-banner {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: 6rem;
    height: 750px;
}

.homenew-banner img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}


.fade-in-animation {
    animation: fadeIn 1s ease-in-out;
}

.homenew-banner .prev-button, .homenew-banner .next-button {
    position: absolute;
    top: 2.5rem;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 0;
    cursor: pointer;
    z-index: 1;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: 900;
}
  
.homenew-banner .prev-button {
    right: 100px;
}
.homenew-banner .next-button {
    right: 30px;
}
@media only screen and (max-width: 600px) {
    .homenew-banner {
        margin-top: 7rem;
        height: 220px;
    }
}