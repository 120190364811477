.scroll-top-button {
    position: fixed;
    bottom: 15px; 
    right: 20px; 
    transition: opacity 0.3s ease;
    opacity: 0;
    z-index: 10;
}

.scroll-top-button.show {
    opacity: 1;
}

.scroll-top-button .btn {
    padding: 3px;
    cursor: pointer;
    background: transparent;
    border: 2px solid #39151b;
    outline: none;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    color: #39151b;
    font-size: 2.5rem;
    font-weight: bold;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.scroll-top-button:hover .btn {
    color: #166736;
    border: 2px solid #166736;
}
@media (max-width: 600px) {
    .scroll-top-button {
        right: 0px; 
    }
}