.whatsapp {
    position: fixed;
    bottom: 70px; 
    right: 20px; 
    transition: opacity 0.3s ease;
    opacity: 1;
    z-index: 10;
}

.whatsapp .btn {
    padding: 5px;
    cursor: pointer;
    background:  #25d366;
    outline: none;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whatsapp .btn:hover{
    transform: rotate(45deg);
}
@media (max-width: 600px) {
    .whatsapp {
        right: 0px; 
    }
}