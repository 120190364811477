.mainsec{
    display: flex;
}
.mainsec .sec1{
    width: 50%;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.mainsec .sec1 p{
    margin-left: 100px;
    font-size: 1.3rem;
    margin-right: 20px;
}
.mainsec .sec2{
    width: 50%;
    display: flex;
    flex-direction: column;
    color: rgb(255, 255, 255);
    font-size: 1.3rem;
    justify-content: center;
    align-items: center;
}
.mainsec .sec2 .img {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.mainsec .sec2 .img img{
    width: 200px;
    height: 200px;
    object-fit: contain;
    background-color: white;
    border-radius: 50%;
}

.mainsec .sec2 .img .studentname{
    font-size: 1.5rem;
}
.mainsec .sec2 .img .coursename{
    font-size: 12px;
    text-align: center;
    margin-bottom: 30px;
}
@media only screen and (max-width: 600px) {
    .mainsec .sec1 p{
        margin-right: 10px;
        margin-left: 10px;
        font-size: 16px;
    }
    .mainsec{
        flex-direction: column;
    }
    .mainsec .sec1{
        width: 100%;
        order: 2;
        color: white;
        height: 100%;
        margin-bottom: 20px;
    }
    .mainsec .sec2{
        color: rgb(0, 0, 0);
        width: 100%;
        order: 1;
    }
    .mainsec .sec2 .img img{
        order: 2;
        width: 150px;
        height: 150px;
    }
    .mainsec .sec2 .img p{
        order: 1;
    }
}