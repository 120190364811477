.homeadmin{
    margin-left: 250px;
    background-color: rgba(206, 206, 206, 0.315);
    height: 100%;
    min-height: calc(100vh - 8rem) !important;
}
.homeadmin .head{
    font-size: 1.5rem;
    font-weight: 900;
    padding: 30px 0;
    margin-left: 30px;
}
.homeadmin .cards{
    display: grid;
    margin: 0 30px;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 2rem;
}
.homeadmin .cards .icard{
    height: 100px;
    width: 100%;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}
.homeadmin .cards .icard.course{
    background-color: #166736;
}
.homeadmin .cards .icard.contact{
    background-color: #39151b;
}
.homeadmin .cards .icard.testimonials{
    background-color: black;
}
.homeadmin .cards .icard .icon{
    width: calc(50%-40px) !important;
    margin-left: 20px;
    font-size: 2rem;
    font-weight: 900;
}
.homeadmin .cards .icard .count{
    width:calc(50%-40px) !important;
    margin-right: 20px;
}
.homeadmin .cards .icard .count .num{
    margin: 5px 0;
    font-size: 1.7rem;
    font-weight: 700;
    width: 100%;
    min-height: 40px;
    text-align: end;
}
.homeadmin .cards .icard .count .text{
    margin: 5px 0;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    text-align: end;
}

@media only screen and (max-width: 600px) {
    .homeadmin{
        margin-left: 0;
        min-height: 100% !important;
        padding-bottom: 150px;
    }
    .homeadmin .cards{
        grid-template-columns: repeat(1,1fr);
    }
}