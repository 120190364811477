.countssection{
    width: 100%;
}
.countssection .sec1{
    display: flex;
}
.countssection .sec1 .texts{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
}
.countssection .sec1 .texts .span{
    margin-top: 20px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
}
.countssection .sec1 .texts .span:hover{
    color: #39151b;
}
.countssection .sec1 .texts .dot{
    width: 8px;
    border-radius: 50%;
    height: 8px;
    background-color: #39151b;
    margin-top: 25px;
    margin-left: 30px;
}
.countssection .sec1 .emblem{
    height: 100%;    
}
.countssection .sec1 .emblem img{
    height: 250px;
    width: 250px;
    animation: spin 10s linear infinite;
}
.countssection .sec2{
    height: 200px;
    margin-left: 210px;
    display: grid;
    grid-template-columns: repeat(4, 1fr) ;
}
.countssection .sec2 .count{
    margin-right: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.countssection .sec2 .count .verticalbar{
    height: 80px;
    background-color: #166736;
    width: 2px;
}
.countssection .sec2 .count .text{
    margin-right: 20px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-align: center;
}
.countssection .sec2 .count .text .number{
    font-size: 4rem;
}
.countssection .sec2 .count .text .string{
    font-size: 2rem;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@media only screen and (max-width: 600px) {
    .countssection .sec1{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .countssection .sec1 .texts{
        width: 100%;
        height: 100%;
        display: flex;
        padding-bottom: 10px;
        justify-content: center;
        align-items: flex-start;
    }
    .countssection .sec1 .texts .span{
        margin-top: 20px;
        margin-left: 5px;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
    }
    .countssection .sec1 .texts .span:hover{
        color: #39151b;
    }
    .countssection .sec1 .texts .dot{
        width: 8px;
        border-radius: 50%;
        height: 8px;
        background-color: #39151b;
        margin-top: 25px;
        margin-left: 5px;
    }
    .countssection .sec1 .emblem{
        height: 100%;    
    }
    .countssection .sec1 .emblem img{
        height: 130px;
        width: 130px;
    }
    .countssection .sec2{
        height: 100%;
        grid-template-columns: repeat(2, 1fr) ;
        align-items: center;
        margin-left: 10px;
    }
    .countssection .sec2 .count{
        margin-bottom: 30px;
        margin-right: 0;
    }
    .countssection .sec2 .count .verticalbar{
        height: 0;
        margin-top: 0;
        width: 0;
    }
    .countssection .sec2 .count .text{
        margin-right: 0;
    }
    .countssection .sec2 .count .text .number{
        font-size: 3rem;
    }
    .countssection .sec2 .count .text .string{
        font-size: 1rem;
    }
}