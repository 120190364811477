.mynewnav{
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 10000;
    background-color: #ffffff;
    height: 6rem;
    color: rgb(0, 0, 0);
    box-shadow: 0 1px 10px 0px rgba(0, 0, 0, 0.3);
}
.mynewnav .logosec{
    width: 17%;
    padding-left: 10px;
}

.mynewnav .logosec img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.mynewnav .connectsec{
    cursor: pointer;
    height: 100%;
    width: 25%;
    display: flex;
    justify-content: space-around;
    align-items: center; 
}
.mynewnav .connectsec .mytogglebutton{
    font-size: 2rem;
    display: none;
}
.mynewnav .connectsec .sociallinks{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mynewnav .connectsec .sociallinks .phone{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    background-color: green;
    font-size: 1.2rem;
    color: white;
    font-weight: 900;
    border-radius: 50%;
    padding: 5px;
}
.mynewnav .connectsec .sociallinks .insta{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    background-color: rgb(248, 0, 153);
    font-size: 1.2rem;
    color: white;
    font-weight: 900;
    border-radius: 50%;
    padding: 7px;
}
.mynewnav .connectsec .sociallinks .admin{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    background-color: #39151b;
    font-size: 1.2rem;
    color: white;
    font-weight: 900;
    border-radius: 50%;
    padding: 7px;
}
.mynewnav .connectsec .sociallinks .fb{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    background-color: blue;
    font-size: 1.2rem;
    color: white;
    font-weight: 900;
    border-radius: 50%;
    padding: 7px;
}
.mynewnav .connectsec .sociallinks .num{
    display: flex;
    flex-direction: column;
    margin: 0 5px;
}
.mynewnav .connectsec .sociallinks .num .callus{
    color: #39151b;
    font-size: 12px;
    font-weight: 500;
}
.mynewnav .connectsec .sociallinks .num .dig{
    color: black;
    font-size: 15px;
    font-weight: 700;
}
.mynewnav .linksec{
    height: 100%;
    width: 58%;
    display: flex;
    justify-content: center;
    align-items: center;    
}
.mynewnav .linksec .links{
    display: flex;
    justify-content: center;
    align-items: center;
}
.mynewnav .linksec .links .horizotalline{
    display: none;
}
.mynewnav .linksec .links .link{
    margin: 0 10px;
    text-decoration: none;
    color: black;
    font-size: 18px;
    transition: all 0.5s ease;
}
.mynewnav .linksec .links .link:hover{
    color: #166736;
    font-weight: 500;
}
.mynewnav .linksec .closebutton{
    display: none;
    cursor: pointer;
}
.mynewnav .linksec .dropdownmain{
    position: relative;
}
.mynewnav .linksec .dropdownmain .dropdown{
    display: none;
    position: absolute;
    background-color: #ffffff93;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.438);
}

.mynewnav .linksec .dropdownmain .dropdown .link {
    padding: 12px 16px;
    margin: 0 0;
    text-decoration: none;
    display: block;
    color: #39151b;
    border-radius: 10px;
}
  
.mynewnav .linksec .dropdownmain .dropdown .link:hover {
    background-color: #1667364d;
}
  
.mynewnav .linksec .dropdownmain:hover .dropdown {
    display: block;
}

@media (max-width: 600px) {
    .mynewnav{
        top: 0;
        position: fixed;
        flex-direction: column;
        height: 7rem;
    }
    .mynewnav .logosec{
        order: 1;
        width: 100%;
        padding: 0;
        height: 4rem;
        display: flex;
        justify-content: center;
    }
    .mynewnav .logosec img{
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
    .mynewnav .connectsec{
        order: 2;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 10px;
    }
    .mynewnav .connectsec .mytogglebutton{
        margin-left: 10px;
        display: block;
    }
    .mynewnav .connectsec .sociallinks{
        margin-right: 10px;
    }
    .mynewnav .connectsec .sociallinks .num{
        display: none;
    }
    .mynewnav .linksec{
        position: fixed;
        order: 3;
        margin-left: 0%;
        display: none;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        justify-content: flex-start;
        align-items: flex-start; 
        background-color: rgb(255, 255, 255);
        z-index: 100000;
        transform: translateX(100%);
        transition: transform 0.5s ease;
    }
    .mynewnav .linksec.open{
        display: flex;
        transform: translateX(0);
        padding-top: 10px;
    }
    .mynewnav .linksec .links .horizotalline{
        display: block;
        height: 1px;
        width: 90%;
        margin-left: 10px;
        margin-top: 5px;
        margin-bottom: 10px;
        background-color: rgb(92, 87, 87);
    }
    .mynewnav .linksec .links{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .mynewnav .linksec .dropdownmain{
        position: static;
    }
    .mynewnav .linksec .dropdownmain .dropdown{
        display: none;
        position: static;
        margin-left: 20px;
        box-shadow: none;
        z-index: 1;
        margin-bottom: 10px;
    }
    .mynewnav .linksec .dropdownmain:hover .dropdown {
        display: none;
    }
    .mynewnav .linksec .dropdownmain .dropdown.show {
        display: block;
    }
    .mynewnav .linksec .dropdownmain .dropdown .link {
        padding: 10px 10px;
    }
    .mynewnav .linksec .link{
        margin: 0 10px;
        text-decoration: none;
        color: black;
        font-size: 18px;
    }
    .mynewnav .linksec .closebutton{
        display: block;
        width: 90%;
        text-align: end;
        font-size: 2rem;
    }
}