.homemessagecontainer{
    margin-top: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homemessageimage{
    height: 300px;
    width: 300px;
    margin-right: 100px;
  }
  .homemessageimage img{
    height: 100%;
    width: 100%;
    border-radius: 10%;
    object-fit: cover;
    object-position: top;
  }
  .secondmessagecontainer{
    width: 50%;
    height: 100%;
    
  }
  .secondmessagecontainer .message{
    width: 100%;
    height: 300px;
    background: url(../assets/message_bg.png) no-repeat; 
    background-size: cover;
    background-position: 100% 100%;
    display: flex;
    justify-content: center;
  }
  .secondmessagecontainer .message p{
    margin-top: 50px;
    width: 70%;
    font-size: 16px;
    color: white;
    font-weight: 500;
  }
  .secondmessagecontainer .nameofdirector{
    width: 100%;
    margin-top: 0;
  }
  .secondmessagecontainer .nameofdirector .homemessagename{
    margin-left: 40px;
    font-weight: bold;
    font-size: 26px;
  }
  .secondmessagecontainer .nameofdirector .homemessagerole{
    margin-left: 40px;
    font-weight: bold;
    font-size: 18px;
    color:#166736;
  }
  @media (max-width: 768px) {
    .homemessagecontainer{
        flex-direction: column;
      }
      .homemessageimage{
        height: 300px;
        width: 300px;
        margin-right: 0;
        object-fit: cover;
        object-position: top;
        margin-bottom: 30px;
      }
      .secondmessagecontainer{
        width: 100%;   
      }
      .secondmessagecontainer .message{
        width: 90%;
        height: 100%;
        margin-top: 20px;
        margin: 0 5%;
        background: none;
        background-color: #166736;
        border-radius: 10px;
        /* background-size: cover;
        background-position: 10%; */
        justify-content: center;
        align-items: center;
      }
      .secondmessagecontainer .message p{
        font-size: 13px;
        font-weight: 400;
        margin: 10px 0;
        /* color: #166736; */

      }
      .secondmessagecontainer .nameofdirector{
        width: 100%;
        margin-top: 40px;
        margin-bottom: 20px;
      }
      .secondmessagecontainer .nameofdirector .homemessagename{
        margin-left: 40px;
        font-weight: bold;
        font-size: 26px;
      }
      .secondmessagecontainer .nameofdirector .homemessagerole{
        margin-left: 40px;
        font-weight: bold;
        font-size: 18px;
        color:#166736;
      }
  }