.call {
    position: fixed;
    bottom: 130px; 
    right: 20px; 
    transition: opacity 0.3s ease;
    opacity: 1;
    z-index: 10;
}

.call .btn {
    padding: 5px;
    cursor: pointer;
    background:  #39151b;
    border: 1px solid white;
    outline: none;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    color: #FFFFEF;
    font-size: 1.3rem;
    font-weight: bold;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.call .btn:hover{
    background-color: #000000;
}
@media (max-width: 600px) {
    .call {
        right: 0px; 
    }
}