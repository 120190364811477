.hometestimonial {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 100px;
}

.hometestimonial::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 50%;
    width: 50%;
    height: 100%;
    background: url(../assets/backgrounds/bg1.webp) no-repeat; 
    background-size: cover;
    background-position: center;
    z-index: -1;
}
.hometestimonial .testihead{
    width: 50%;
    margin-left: 40px;
    font-weight: 900;
    font-size: 3rem;
}
.hometestimonial .testimonial {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
}

.hometestimonial .testimonial span {
    font-size: 1.7rem;
    font-weight: 900;
}

.hometestimonial .testimonial button {
    display: flex;
    color: rgb(0, 0, 0);
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
    background-color: #166736;
    border: none;
    color: white;
    padding-top: 5px;
    cursor: pointer;
    transition: color 0.2s ease-in, background-color 0.2s ease-in;
}

.hometestimonial .testimonial button:hover {
    background-color: black;
    color: white;
}
.hometestimonial .testimonial .prev-button {
    left: 30px;
}
.hometestimonial .testimonial .next-button {
    left: 100px;
}

@media only screen and (max-width: 600px) {
    .hometestimonial {
        width: 100%;
        margin-bottom: 50px; 
    }
    .hometestimonial .testihead{
        width: 90%;
        margin-left: 10px;
        margin-top: 10%;
        margin-bottom: 20px;
        font-size: 2rem;
        text-align: center;
    }
    .hometestimonial::after {
        top: 30%;
        left: 0%;
        width: 100%;
        height: 100%;
    }
    
    .hometestimonial .testimonial button {
        top: 55%;
        background-color: white;
        height: 40px;
        width: 40px;
        color: black;
    }
    .hometestimonial .testimonial .prev-button {
        left: 20px;
    }
    .hometestimonial .testimonial .next-button {
        left: 70px;
    }
    .hometestimonial .backimg{
        visibility: hidden;
        height: 0;
        width: 0;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutToRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}


