.stdmain{
    height: calc(100vh - 9rem);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.stdmain .stdcard{
    width: 400px;
    height: 250px;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(0, 0, 0, 0.377);
    border-radius: 10px;
}
.stdmain .stdcard .head{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 2rem;
    color: white;
    background-color: #166736;
    border-radius: 10px 10px 0 0;
}
.stdmain .stdcard .stdcardmain{
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.stdmain .stdcard .stdcardmain .input{
    height: 40px;
    width: 80%;
    border: 1px solid rgba(0, 0, 0, 0.329);
    border-left: 3px solid #39151b;
    border-radius: 10px;
}
.stdmain .stdcard .stdcardmain .input input{
    width: 90%;
    height: 90%;
    border-radius: 10px;
    outline: none;
    border: none;
    margin-left: 3%;
    font-size: 16px;
}
.stdmain .stdcard .stdcardmain .button{
    margin-top: 20px;
    width: 100%;
    text-align: center;
}
.stdmain .stdcard .stdcardmain .button button{
    height: 40px;
    padding: 5px 20px;
    font-size: 1rem;
    background-color: #39151b;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 10px;
}
.stdmain .stdcard .stdcardmain .button button:hover{
    background-color: #166736;
}
.foot{
    width: 100%;
    height: 3rem;
    border-top: 1px solid rgba(0, 0, 0, 0.274);
    display: flex;
    justify-content: center;
    align-items: center;
}

.stdnav{
    width: 100%;
    display: flex;
    background-color: #ffffff;
    height: 6rem;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0.308);
}
.stdnav .logosec{
    width: 20%;
    padding-left: 10px;
}
.stdnav .logosec img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
@media (max-width: 768px) {
    .stdmain{
        height: 100%;
        margin: 5rem 0;
    }
    .stdnav{
        width: 100%;
        display: flex;
        background-color: #ffffff;
        height: 6rem;
        color: rgb(0, 0, 0);
        box-shadow: 0 1px 10px 0px rgba(0, 0, 0, 0.3);
    }
    .stdnav .logosec{
        width: 100%;
        padding-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stdnav .logosec img{
        width: 80%;
        height: 80%;
        object-fit: contain;
    }
    .stdmain .stdcard{
        width: 90%;
        height: 250px;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid rgba(0, 0, 0, 0.377);
        border-radius: 10px;
        margin-bottom: 4rem;
    }
}