.homenewsevents {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    background: url(../assets/ill5.webp) no-repeat;
    background-size: cover;
    height: 800px;
}
.homenewsevents .head{
    margin-bottom: 20px;
    font-size: 2.5rem;
    font-weight: bold;
    height: 50px;
}
.homenewsevents .sec{
    display: flex;
    height: 100%;
    width: 100%;
}
.homenewsevents .sec .news {
    margin-top: 30px;
    width: 450px;
    margin-left: calc(100% - 450px) !important;
    display: flex;
    flex-direction: column;
}
.homenewsevents .sec .news .image{
    overflow: hidden;
    width: 400px;
    height: 650px;
}
.homenewsevents .sec .news .date{
    margin: 30px 0 10px 0;
    font-size: 1rem;
    color: #166736;
}
.homenewsevents .sec .news .title{
    height: 100%;
    margin: 10px 0;
    font-size: 1.5rem;
    font-weight: bold;
}
.homenewsevents .sec .news .image img{
    width: 400px;
    height: 400px;
}
.homenewsevents .sec .news .buttons{
    display: flex;
}
.homenewsevents .sec .news span {
    font-size: 36px;
    font-weight: 900;
}
.homenewsevents .sec .news .allbutton{
    margin-top: 30px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border: 2px solid #166736;
    color: black;
    border-radius: 30px;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.homenewsevents .sec .news .allbutton .btnarrow{
    font-size: 2rem;
    margin-left: 10px;
    color: #166736;
    transition: all 0.3s ease;  
}
.homenewsevents .sec .news .allbutton:hover{
    background-color: #166736;
    color: white;
    border: 0;
}
.homenewsevents .sec .news .allbutton:hover .btnarrow{
    color: white;
    margin-left: 15px;
}
.homenewsevents .sec .news button {
    display: flex;
    color: rgb(0, 0, 0);
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #166736;
    border: none;
    padding-top: 5px;
    cursor: pointer;
    margin: 10px;
    transition: color 0.2s ease-in, background-color 0.2s ease-in;
}

.homenewsevents .sec .news button:hover {
    background-color: black;
    color: white;
}
.homenewsevents .sec .video{
    width: 60%;
    margin-top: 30px;
}

.slide-in-right {
    animation: slideInFromRight 0.5s forwards;
}

.slide-out-left {
    animation: slideOutToLeft 0.5s forwards;
}

.slide-in-left {
    animation: slideInFromLeft 0.5s forwards;
}

.slide-out-right {
    animation: slideOutToRight 0.5s forwards;
}

@media (max-width: 600px) {
    .homenewsevents {
        padding-bottom: 0;
        background: none;
        height: 100%;
    }
    .homenewsevents .head{
        margin-bottom: 20px;
        font-size: 1.8rem;
        margin-left: 10px;
    }
    .homenewsevents .sec{
        display: flex;
        flex-direction: column;
    }
    .homenewsevents .sec .news{
        width: 100%;
        margin-left: 0 !important;
    }
    .homenewsevents .sec .news .image{
        width: 100%;
        height: 550px;
    }
    .homenewsevents .sec .news.second{
        visibility: hidden;
        display: none;
    }
    .homenewsevents .sec .news .image img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .homenewsevents .sec .news .allbutton{
        margin-top: 20px;
        margin-left: 10px;
    }
    .homenewsevents .sec .news .date{
        margin: 0;
        margin-top: 10px;
        margin-left: 10px;
    }
    .homenewsevents .sec .news .title{
        margin: 0;
        margin-left: 10px;
    }
    .homenewsevents .sec .video{
        width: 100%;
    }
    .homenewsevents .sec .news button{
        height: 40px;
        width: 40px;
    }
}
@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes slideOutToRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

