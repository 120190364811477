.addpage{
    min-height: calc(100vh - 8rem) !important;
    background-color: rgba(206, 206, 206, 0.315);
}
.viewtable{
    width: calc(100% - 250px);
    margin-left: 250px;
    height: 100%;
    padding-bottom: 100px;
}

.viewcard{
    width: 94%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin-left: 3%;
    padding: 5px 0;
    margin-bottom: 20px;
}
.viewcard .item{
    display: flex;
    flex-direction: column;
    width: 96%;
    margin:10px 2%;
}
.viewcard .item .head{
    font-weight: 700;
    font-size: 16px;
    color: #39151b;
}
.viewcard .item .content{
    font-weight: 400;
    font-size: 16px;
}
.viewcard .buttons{
    display: flex;
}

.viewtable.blur {
    filter: blur(5px);
    pointer-events: none;
    user-select: none;
}

.viewtable table{
    width: 90%;
    margin-left: 5%;
    height: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}
.addpage .pagehead{
    width: 90%;
    margin-left: 5%;
    padding-top: 40px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 900;
}
.viewtable .actionbutton{
    color: white; 
    height: 40px;
    width: 70px;
    margin: 10px;
    font-size: 1rem;
    border: none;
    outline: none;
    border-radius: 10px;
    transition: all 0.3s ease;
    opacity: 1;
    cursor: pointer;
}
.viewtable .actionbutton:hover{
    opacity: 0.9;
}
.viewtable .actionbutton.edit{
    background-color: #166736;
}
.viewtable .actionbutton.delete{
    background-color: #39151b;
}
.viewtable .btnlink{
    text-decoration: none;
    color: white;
}
.viewtable table th{
    padding: 20px 0;
    border-bottom: 1px solid black;
    border: 1px solid black;
}
.viewtable table td{
    padding: 10px 10px;
    text-align: start;
    border: 1px solid black;
}


.adminform{
    width: calc(100% - 250px);
    margin-left: 250px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;
}
.adminform .field{
    width: 90%;
    margin-left: 5%;
}
.adminform .label{
    font-size: 12px;
    margin-left: 10px;
    color: #39151b;
    margin-top: 20px;
}
.adminform .imagecontainer{
    height: 100%;
    width: 90%;
    margin-left: 5%;
}
.adminform .imagecontainer img{
    height: 20%;
    width: 20%;
    object-fit: contain;
    margin-top: 20px;
}
.adminform .input{
    display: flex;
    /* justify-content: center;
    align-items: center; */
    height: 50px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.336);
    border-left: 3px solid #39151b;
    border-radius: 10px;
    cursor: pointer;
}
.adminform .textarea{
    display: flex;
    /* justify-content: center;
    align-items: center; */
    height: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.336);
    border-left: 3px solid #39151b;
    border-radius: 10px;
    cursor: pointer;
}
.adminform .input input{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    height: 95%;
    background:transparent;
    width: 96%;
    padding: 0 2%;
    border: none;  
    outline: none;  
    border-radius: 10px;
    font-size: 18px;
}
.adminform .textarea textarea{
    display: flex;
    /* justify-content: center;
    align-items: center; */
    height: 95%;
    background:transparent;
    width: 96%;
    padding: 0 2%;
    border: none;  
    outline: none;  
    border-radius: 10px;
    font-size: 18px;
}
.adminform button{
    width: 90%;
    margin-left: 5%;
    height: 40px;
    font-size: 20px;
    font-weight: 700;
    color: white;
    background-color: #39151b;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-top: 20px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .adminform{
        width: 100%;
        margin-left: 0;
        margin-bottom: 50px;
    }
    .viewtable{
        width: 100%;
        margin-left: 0;
        margin-bottom: 50px;
    }
}