.aboutcount{
  position: relative;
  padding-bottom: 50px;
}
.errorpage{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.aboutcount::after{
  content: '';
  position: absolute;
  top: 23%; 
  left: 0;
  width: 100%;
  left: 0%;
  height: 77%; 
  background: linear-gradient(#39151ba1, #39151ba1),url(../assets/ill2.webp) no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
.coursecategories{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.coursecategories .catbutton{
  font-size: 1rem;
  font-weight: 600;
  background-color: #166736;
  color: white;
  border-radius: 30px;
  margin: 10px;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
}
.coursecategories .catbutton.active{
  background-color: #39151b;
  color: white;
}
.courcescard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 50px 40px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.headpage{
  padding-top: 6rem;
  background: url(../assets/backgrounds/bg1.webp) no-repeat; 
  background-position: 50% 100%;
  background-size: cover;
  height: 6rem;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
.ccard {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #39151b;
  text-decoration: none;
} 
.cimg img {
  max-width: 100%;
  border-radius: 20px 20px 0 0;
}
.ctext{
  height: 90px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-align: center;
}
.cdate{
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-weight: 400;
  font-size: 0.8rem;
  color: white;
}

@media (max-width: 768px) {
  .aboutcount{
    padding-bottom: 0;
  }
  .aboutcount::after{
    top: 28%; 
    left: 0;
    width: 100%;
    height: 72%; 
    background-size: cover;  
  }
  .coursecategories{
    width: 96%;
    margin-left: 2%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1rem;
  }
  .coursecategories .catbutton{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 0 20px;
    height: 50px;
  }
  .courcescard {
    grid-template-columns: repeat(1, 1fr); 
    margin: 20px 40px;
  }
  .headpage{
    padding-top: 7rem;
  }
  
}