.apply {
    position: fixed;
    bottom: 50%;
    right: -25px; 
    transition: opacity 0.3s ease;
    opacity: 1;
    z-index: 10;
}

.apply .btn {
    transform: rotate(270deg);
    text-decoration: none;
    padding: 0;
    cursor: pointer;
    background:  #166736;
    outline: none;
    border: 0 solid white;
    border-radius: 0%;
    height: 35px; 
    width: 100px; 
    color: #FFFFEF;
    font-size: 1rem;
    font-weight: normal;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.apply .btn:hover{
    background-color: #000000;
}
@media (max-width: 600px) {
    /* .apply{
        right: -8%;
    } */
}