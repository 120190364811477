.homecard{
    display: flex;
    align-items: center;
    height: 35rem;
    padding: 100px 0;
}
.homecard .sec1{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    background: url(../assets/ill4.webp) no-repeat;
    background-position: left center;
    background-size: auto 100%;
    align-items: center;
}
.homecard .sec2{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    background: url(../assets/ill3.webp) no-repeat;
    background-position: right center;
    background-size: auto 100%;
    align-items: center;
}
.mycard{
    display: flex;
    flex-direction: column;
    width: 20rem;
    height: 100%;
}
.mycard .cardtext1{
    background-color: #166736;
    color: white;
    height: 50%;
    width: 100%;
    border-radius: 15px 15px 0 0;
    transition: background-color 0.8s ease;
}
.mycard .cardtext2{
    background-color: #39151b;
    color: white;
    height: 50%;
    width: 100%;
    border-radius: 15px 15px 0 0;
    transition: background-color 0.8s ease;
}
.mycard .cardtext1 .text,.mycard .cardtext2 .text{
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 50px;
}
.mycard .cardtext1 .text .coursedetails,.mycard .cardtext2 .text .coursedetails{
    font-size: 1.5rem;
}
.mycard .cardtext1 .iconbutton,.mycard .cardtext2 .iconbutton{
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 50px;
}
.mycard .cardtext2 .iconbutton .arrow{
    background-color: #166736;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 50px;
    font-size: 2rem;
    transition: background-color 0.8s ease;
}
.mycard .cardtext1 .iconbutton .arrow{
    background-color: #39151b;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 50px;
    font-size: 2rem;
    transition: background-color 0.8s ease;
}
.mycard .card-image{
    height: 50%;  
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 0 0 15px 15px;
}
.mycard .card-image img{
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
    position: absolute;
    top: 0;
    left: 0;
}
.mycard:hover .cardtext2,.mycard:hover .cardtext1{
    background-color: #000000;
}
.mycard:hover .cardtext2 .iconbutton .arrow{
    background-color: #39151b;
}
.mycard:hover .cardtext1 .iconbutton .arrow{
    background-color: #166736;
}
.mycard .card-image:hover img{
    transform: scale(1.2);
}

@media screen and (max-width: 600px){
    .homecard{
        flex-direction: column;
        width: 100%;
        height: 24rem;
        padding: 30px 0;
    }
    .homecard .sec1{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        background: none;
        margin: 10px 0;
    }
    .homecard .sec2{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        background:none;
        margin: 10px 0;
    }
    .mycard{
        display: flex;
        flex-direction: row; 
        width: 90%;
        height: 100%;
        margin-bottom: 20px;
    }
    .mycard .cardtext1{
        height: 100%;
        width: 100%;
        border-radius: 15px 0 0 15px;
    }
    .mycard .cardtext2{
        height: 100%;
        width: 100%;
        border-radius: 15px 0 0 15px;
    }
    .mycard .cardtext1 .text,.mycard .cardtext2 .text{
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;
    }
    .mycard .cardtext1 .text .coursedetails,.mycard .cardtext2 .text .coursedetails{
        font-size: 1.5rem;
    }
    .mycard .cardtext1 .iconbutton,.mycard .cardtext2 .iconbutton{
        margin-right: 10px;
    }
    .mycard .cardtext1 .iconbutton .arrow,.mycard .cardtext2 .iconbutton .arrow{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 45px;
        border-radius: 50px;
        font-size: 2rem;
        transition: background-color 0.8s ease;
    }
    .mycard .card-image{
        height: 100%;  
        width: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 0 15px 15px 0;
    }
}